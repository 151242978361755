import { all } from 'redux-saga/effects';
import { trainingsService } from '../services';
import { fetchSaga } from '../../../utils';
import { membersActions } from './members.slice';

export function* membersRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getMembers',
      actions: membersActions,
      api: {
        method: trainingsService.getTrainings,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postMembers',
      actions: membersActions,
      api: {
        method: trainingsService.addTraining,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putMember',
      actions: membersActions,
      api: {
        method: trainingsService.updateTraining,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteMember',
      actions: membersActions,
      api: {
        method: trainingsService.deleteTraining,
        callback: (res) => res,
      },
    }),
  ]);
}
