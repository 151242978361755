import { all } from 'redux-saga/effects';
import { expertsService } from '../services';
import { fetchSaga } from '../../../utils';
import { expertsActions } from './experts.slice';

export function* expertsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getSpheres',
      actions: expertsActions,
      api: {
        method: expertsService.getProjects,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postSpheres',
      actions: expertsActions,
      api: {
        method: expertsService.addSphere,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putSphere',
      actions: expertsActions,
      api: {
        method: expertsService.updateSphere,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteSphere',
      actions: expertsActions,
      api: {
        method: expertsService.deleteProject,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getExperts',
      actions: expertsActions,
      api: {
        method: expertsService.getExperts,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postExpert',
      actions: expertsActions,
      api: {
        method: expertsService.addProject,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putExpert',
      actions: expertsActions,
      api: {
        method: expertsService.updateProject,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteExpert',
      actions: expertsActions,
      api: {
        method: expertsService.deleteExpert,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getPublications',
      actions: expertsActions,
      api: {
        method: expertsService.getPublications,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postPublication',
      actions: expertsActions,
      api: {
        method: expertsService.addPublication,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putPublication',
      actions: expertsActions,
      api: {
        method: expertsService.updatePublication,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deletePublication',
      actions: expertsActions,
      api: {
        method: expertsService.deletePublication,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getTeaching',
      actions: expertsActions,
      api: {
        method: expertsService.getTeaching,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postTeaching',
      actions: expertsActions,
      api: {
        method: expertsService.addTeaching,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putTeaching',
      actions: expertsActions,
      api: {
        method: expertsService.updateTeaching,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteTeaching',
      actions: expertsActions,
      api: {
        method: expertsService.deleteTeaching,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getMedia',
      actions: expertsActions,
      api: {
        method: expertsService.getMedia,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postMedia',
      actions: expertsActions,
      api: {
        method: expertsService.addMedia,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putMedia',
      actions: expertsActions,
      api: {
        method: expertsService.updateMedia,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteMedia',
      actions: expertsActions,
      api: {
        method: expertsService.deleteMedia,
        callback: (res) => res,
      },
    }),
  ]);
}
