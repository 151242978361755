import { all } from 'redux-saga/effects';
import { requestsService } from '../services';
import { fetchSaga } from '../../../utils';
import { requestsActions } from './requests.slice';

export function* requestsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getRequests',
      actions: requestsActions,
      api: {
        method: requestsService.getRequests,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putRequest',
      actions: requestsActions,
      api: {
        method: requestsService.updateRequest,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteRequest',
      actions: requestsActions,
      api: {
        method: requestsService.deleteRequest,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getTrainingRequests',
      actions: requestsActions,
      api: {
        method: requestsService.getTrainingRequests,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putTrainingRequest',
      actions: requestsActions,
      api: {
        method: requestsService.updateTrainingRequest,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteTrainingRequest',
      actions: requestsActions,
      api: {
        method: requestsService.deleteTrainingRequest,
        callback: (res) => res,
      },
    }),
  ]);
}
