import { all, fork } from 'redux-saga/effects';
import { articlesRootSaga } from '../modules/services/store/articles.saga';
import { expertsRootSaga } from '../modules/projects/store';
import { settingsRootSaga } from '../modules/settings/store/settings.saga';
import { membersRootSaga } from '../modules/trainings/store/members.saga';

import { commonRootSaga } from './common';
import { requestsRootSaga } from '../modules/requests/store';

export function* rootSaga() {
  yield all([
    fork(commonRootSaga),
    fork(settingsRootSaga),
    fork(articlesRootSaga),
    fork(expertsRootSaga),
    fork(membersRootSaga),
    fork(requestsRootSaga),
  ]);
}
