import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

const Services = lazy(() =>
  import('../modules/services/pages/services').then((item) => ({
    default: item.Services,
  }))
);
const Service = lazy(() =>
  import('../modules/services/pages/service').then((item) => ({
    default: item.Article,
  }))
);
const ServicesForm = lazy(() =>
  import('../modules/services/pages/services-form').then((item) => ({
    default: item.ArticlesForm,
  }))
);
const Projects = lazy(() =>
  import('../modules/projects/pages/projects').then((item) => ({
    default: item.Projects,
  }))
);
const Project = lazy(() =>
  import('../modules/projects/pages/project').then((item) => ({
    default: item.Project,
  }))
);
const ProjectsForm = lazy(() =>
  import('../modules/projects/pages/projects-form').then((item) => ({
    default: item.ProjectsForm,
  }))
);
const Trainings = lazy(() =>
  import('../modules/trainings/pages/trainings').then((item) => ({
    default: item.Trainings,
  }))
);
const TrainingsForm = lazy(() =>
  import('../modules/trainings/pages/trainings-form').then((item) => ({
    default: item.TrainingsForm,
  }))
);
const Requests = lazy(() =>
  import('../modules/requests/pages/requests').then((item) => ({
    default: item.Requests,
  }))
);
const Settings = lazy(() =>
  import('../modules/settings/pages/settings').then((item) => ({
    default: item.Settings,
  }))
);

const routes = [
  {
    path: '/',
    children: [
      { index: true, element: <Services /> },
      {
        path: 'article/:id',
        element: <Service />,
      },
      {
        path: 'articles/form',
        element: <ServicesForm />,
      },
      {
        path: 'projects',
        element: <Projects />,
      },
      {
        path: 'project/:id',
        element: <Project />,
      },
      {
        path: 'projects/form',
        element: <ProjectsForm />,
      },
      {
        path: 'trainings',
        element: <Trainings />,
      },
      {
        path: 'trainings/form',
        element: <TrainingsForm />,
      },
      {
        path: 'requests',
        element: <Requests />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
];

export const Routes = () => {
  const elements = useRoutes(routes);

  return elements;
};
