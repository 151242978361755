import { Row, Spin } from 'antd';

/**
 * @description Loader component
 * @example
 * <Loader />
 *
 * @returns {React.Component}
 * @see {@link https://ant.design/components/spin}
 * @see {@link https://ant.design/components/row}
 */

export const Loader = ({ text = 'Loading...' }) => {
  return (
    <Row align="middle" justify="center" style={{ height: '100%', width: '100%' }}>
      <Spin tip={text} size="large" />
    </Row>
  );
};
