const VERSION = '_version';
const AUTH_TOKEN = '_access_token';

class StorageService {
  setItem(key, value) {
    window.localStorage.setItem(key, value);
  }

  getItem(key) {
    return window.localStorage.getItem(key);
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.document.cookie = '';
  }

  getAccessToken = () => this.getItem(AUTH_TOKEN);
  removeAccessToken = () => this.removeItem(AUTH_TOKEN);
  setAccessToken = (token) => this.setItem(AUTH_TOKEN, token);

  getVersion = () => this.getItem(VERSION);
  setVersion = (version) => this.setItem(VERSION, version);

  getUserSettings = () =>
    this.getItem('_userSettings') && JSON.parse(this.getItem('_userSettings'));
  removeUserSettings = () => this.removeItem('_userSettings');
}

export const storageService = new StorageService();
