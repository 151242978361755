import { createSlice } from '@reduxjs/toolkit';

const name = 'members';

const initialState = {
  loadMembers: false,
  members: [],
  isAdded: false,
};

export const { actions: membersActions, reducer: membersReducer } = createSlice(
  {
    name,
    initialState,
    reducers: {
      setLoading() {},
      setError(_, { payload }) {
        console.log(payload);
      },

      getMembers(state) {
        state.loadMembers = true;
      },
      getMembersFulfilled(state, { payload }) {
        state.members = payload.data;
        state.loadMembers = false;
      },

      postMembers() {},
      postMembersFulfilled(state, { payload }) {
        state.members = [...state.members, payload.data];
        state.isAdded = true;
      },

      putMember() {},
      putMemberFulfilled(state, { payload }) {
        state.members = state.members.map((member) => {
          if (member.member_id === payload.data.member_id) {
            return payload.data;
          }

          return member;
        });
        state.isAdded = true;
      },

      deleteMember() {},
      deleteMemberFulfilled(state, { payload }) {
        state.members = state.members.filter(
          (member) => member.training_id !== payload.data.training_id
        );
      },

      setIsAdded(state, { payload }) {
        state.isAdded = payload;
      },
    },
  }
);
