import { put, call } from 'redux-saga/effects';

export const tryCatchSagaFactory = (actions) => (saga) =>
  function* (action) {
    try {
      yield call(saga, action);
    } catch (error) {
      yield put(actions.setError(error.body));
    }
  };
