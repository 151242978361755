import { api } from '../../../services';

class RequestsService {
  constructor(api) {
    this.api = api;
  }

  getRequests = () => this.api.get('/requests');
  updateRequest = (payload) =>
    this.api.put(`/sphere/${payload.sphere_id}`, payload);
  deleteRequest = (id) => this.api.delete(`/request/${id}`);

  getTrainingRequests = () => this.api.get('/training-requests');
  updateTrainingRequest = (payload) =>
    this.api.put(`/training-request/${payload.training_request_id}`, payload);
  deleteTrainingRequest = (id) => this.api.delete(`/training-request/${id}`);

  // addPublication = (payload) => this.api.post('/publications', payload);
}

export const requestsService = new RequestsService(api);
