import { useAuth } from './hooks';
import React from 'react';
import { config } from './config';
import { storageService } from './services';

export const useAppProps = () => {
  const token = storageService.getAccessToken();

  React.useEffect(() => {
    if (token) {
      fetch(config.apiUrl + '/verify', {
        headers: {
          Authorization: `Bearer ${storageService.getAccessToken()}`,
        },
      });
    }
  }, [token]);
  const { isAuthenticated } = useAuth();

  return { isAuthenticated };
};
