/* eslint-disable */
import { config } from '../config';
import { storageService } from './storage.service';

export class BaseService {
  constructor(options = {}) {
    this._baseUrl = options.baseUrl || '';
    this._headers = options.headers || {};
  }

  _fetchJSON = async (endpoint, options = {}) => {
    const res = await fetch(this._baseUrl + endpoint, {
      headers: this._headers,
      ...options,
    });

    if (!res.ok) throw new Error(res.statusText);

    if (options.parseResponse !== false && res.status !== 204)
      return res.json();

    return undefined;
  };

  setBasicAuth = (token) => {
    this._headers.Authorization = `${token}`;
    return this;
  };

  setHeader = (key, value) => {
    this._headers[key] = value;
    return this;
  };

  get = (endpoint, options = {}) => {
    return this._fetchJSON(endpoint, {
      ...options,
      method: 'GET',
    });
  };

  post = (endpoint, body, options = {}) => {
    const isFormData = body instanceof FormData;
    return this._fetchJSON(endpoint, {
      method: 'POST',
      ...options,
      body: isFormData ? body : JSON.stringify(body),
    });
  };

  put = (endpoint, body, options = {}) => {
    const isFormData = body instanceof FormData;
    return this._fetchJSON(endpoint, {
      method: 'PUT',
      ...options,
      body: isFormData ? body : JSON.stringify(body),
    });
  };

  delete = (endpoint, body, options = {}) => {
    const isFormData = body instanceof FormData;
    return this._fetchJSON(endpoint, {
      method: 'DELETE',
      ...options,
      body: isFormData ? body : JSON.stringify(body),
    });
  };

  patch = (endpoint, body, options = {}) => {
    const isFormData = body instanceof FormData;
    return this._fetchJSON(endpoint, {
      method: 'PATCH',
      ...options,
      body: isFormData ? body : JSON.stringify(body),
    });
  };
}

export const api = new BaseService({
  baseUrl: config.apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    mode: 'no-cors',
  },
});
