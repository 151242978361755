import { all } from 'redux-saga/effects';
import { settingsService } from '../services';
import { fetchSaga } from '../../../utils';
import { settingsActions } from './settings.slice';

export function* settingsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getUsers',
      actions: settingsActions,
      api: {
        method: settingsService.getUsers,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postUsers',
      actions: settingsActions,
      api: {
        method: settingsService.addUsers,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteUser',
      actions: settingsActions,
      api: {
        method: settingsService.deleteUser,
        callback: (res) => res,
      },
    }),
  ]);
}
