import { createSlice } from '@reduxjs/toolkit';

const name = 'spheres';

const initialState = {
  loading: false,
  loadSpheres: false,
  spheres: [],
  loadExperts: false,
  experts: [],
  isAdded: false,
  publications: [],
  teaching: [],
  media: [],
};

export const { actions: expertsActions, reducer: expertsReducer } = createSlice(
  {
    name,
    initialState,
    reducers: {
      setLoading() {},
      setError(_, { payload }) {
        console.log(payload);
      },

      getSpheres(state) {
        state.loadSpheres = true;
      },
      getSpheresFulfilled(state, { payload }) {
        state.spheres = payload.data;
        state.loadSpheres = false;
      },

      postSpheres() {},
      postSpheresFulfilled(state, { payload }) {
        state.spheres = [...state.spheres, payload.data];
      },

      putSphere() {},
      putSphereFulfilled(state, { payload }) {
        state.spheres = state.spheres.map((sphere) => {
          if (sphere.sphere_id === payload.data.sphere_id) {
            return payload.data;
          }

          return sphere;
        });
      },

      deleteSphere() {},
      deleteSphereFulfilled(state, { payload }) {
        state.spheres = state.spheres.filter(
          (sphere) => sphere.project_id !== payload.data.project_id
        );
      },

      getExperts(state) {
        state.loadExperts = true;
      },
      getExpertsFulfilled(state, { payload }) {
        state.experts = payload.data;
        state.loadExperts = false;
      },

      postExpert() {},
      postExpertFulfilled(state, { payload }) {
        state.experts = [...state.experts, payload.data];
        state.isAdded = true;
      },

      putExpert() {},
      putExpertFulfilled(state, { payload }) {
        state.experts = state.experts.map((expert) => {
          if (expert.expert_id === payload.data.expert_id) {
            return payload.data;
          }

          return expert;
        });
        state.isAdded = true;
      },

      deleteExpert() {},
      deleteExpertFulfilled(state, { payload }) {
        state.experts = state.experts.filter(
          (expert) => expert.expert_id !== payload.data.expert_id
        );
      },

      setIsAdded(state, { payload }) {
        state.isAdded = payload;
      },

      getPublications() {},
      getPublicationsFulfilled(state, { payload }) {
        state.publications = payload.data;
      },

      postPublication() {},
      postPublicationFulfilled(state, { payload }) {
        state.publications = [...state.publications, payload.data];
      },

      putPublication() {},
      putPublicationFulfilled(state, { payload }) {
        state.publications = state.publications.map((publication) => {
          if (publication.publication_id === payload.data.publication_id) {
            return payload.data;
          }

          return publication;
        });
      },

      deletePublication() {},
      deletePublicationFulfilled(state, { payload }) {
        state.publications = state.publications.filter(
          (publication) =>
            publication.publication_id !== payload.data.publication_id
        );
      },

      getTeaching() {},
      getTeachingFulfilled(state, { payload }) {
        state.teaching = payload.data;
      },

      postTeaching() {},
      postTeachingFulfilled(state, { payload }) {
        state.teaching = [...state.teaching, payload.data];
      },

      putTeaching() {},
      putTeachingFulfilled(state, { payload }) {
        state.teaching = state.teaching.map((teaching) => {
          if (teaching.teaching_id === payload.data.teaching_id) {
            return payload.data;
          }

          return teaching;
        });
      },

      deleteTeaching() {},
      deleteTeachingFulfilled(state, { payload }) {
        state.teaching = state.teaching.filter(
          (teaching) => teaching.teaching_id !== payload.data.teaching_id
        );
      },

      getMedia() {},
      getMediaFulfilled(state, { payload }) {
        state.media = payload.data;
      },

      postMedia() {},
      postMediaFulfilled(state, { payload }) {
        state.media = [...state.media, payload.data];
      },

      putMedia() {},
      putMediaFulfilled(state, { payload }) {
        state.media = state.media.map((media) => {
          if (media.media_enquirie_id === payload.data.media_enquirie_id) {
            return payload.data;
          }

          return media;
        });
      },

      deleteMedia() {},
      deleteMediaFulfilled(state, { payload }) {
        state.media = state.media.filter(
          (media) => media.media_enquirie_id !== payload.data.media_enquirie_id
        );
      },
    },
  }
);
