import { theme, Layout } from 'antd';

const { Content: AntContent } = Layout;

export const Content = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AntContent
      style={{
        margin: '24px 16px',
        marginRight: '0',
        padding: '24px',
        minHeight: 280,
        background: colorBgContainer,
        overflow: 'auto',
      }}
    >
      {children}
    </AntContent>
  );
};
