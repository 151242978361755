import { Button } from 'antd';
import { Link } from 'react-router-dom';

const homeButton = (
  <Button type="primary">
    <Link to="/">Back Home</Link>
  </Button>
);

const errorTypes = {
  warning: {
    status: 'warning',
    title: 'There are some problems with your operation.',
    extra: homeButton,
  },
  403: {
    status: '403',
    title: '403',
    subTitle: 'Sorry, you are not authorized to access this page.',
    extra: homeButton,
  },
  404: {
    status: '404',
    title: '404',
    subTitle: 'Sorry, the page you visited does not exist.',
    extra: homeButton,
  },
  500: {
    status: '500',
    title: '500',
    subTitle: 'Sorry, something went wrong.',
    extra: homeButton,
  },
  error: {
    status: 'error',
    title: 'Submission Failed',
    subTitle: 'Please check and modify the following information before resubmitting.',
    extra: homeButton,
  },
  success: {
    status: 'success',
    title: 'Submission Success',
    subTitle: 'Your submission has been received.',
    extra: homeButton,
  },
  info: {
    status: 'info',
    title: 'Your operation has been executed',
    subTitle: null,
    extra: homeButton,
  },
};

export const useErrorProps = ({ status = 'success', ...additional }) => {
  const error = { ...errorTypes[status], ...additional };

  return { error };
};
