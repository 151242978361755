import { all } from 'redux-saga/effects';
import { articlesService } from '../services';
import { fetchSaga } from '../../../utils';
import { articlesActions } from './articles.slice';

export function* articlesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getTopics',
      actions: articlesActions,
      api: {
        method: articlesService.getTopics,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postTopics',
      actions: articlesActions,
      api: {
        method: articlesService.addTopics,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putTopic',
      actions: articlesActions,
      api: {
        method: articlesService.updateTopics,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteTopic',
      actions: articlesActions,
      api: {
        method: articlesService.deleteTopic,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getArticles',
      actions: articlesActions,
      api: {
        method: articlesService.getArticles,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getArticleById',
      actions: articlesActions,
      api: {
        method: articlesService.getArticleById,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'postArticle',
      actions: articlesActions,
      api: {
        method: articlesService.addServices,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'putArticle',
      actions: articlesActions,
      api: {
        method: articlesService.updateServices,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteArticle',
      actions: articlesActions,
      api: {
        method: articlesService.deleteServices,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'getComments',
      actions: articlesActions,
      api: {
        method: articlesService.getComments,
        callback: (res) => res,
      },
    }),
    fetchSaga({
      pattern: 'deleteComment',
      actions: articlesActions,
      api: {
        method: articlesService.deleteComment,
        callback: (res) => res,
      },
    }),
  ]);
}
