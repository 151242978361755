const stage = process.env.NODE_ENV;

const configs = {
  development: {
    apiUrl: 'https://mirpower-backend.onrender.com',
  },
  production: {
    apiUrl: 'https://mirpower-backend.onrender.com',
  },
};

export const config = configs[stage];
export const version = '1.0.0';
