import { hoc } from '../../utils';
import { useLayoutProps } from './layout.props';
import { Layout as AntLayout } from 'antd';
import { Sidebar } from './sidebar';
import { Header } from './header';
import { Content } from './content';

export const Layout = hoc(useLayoutProps, ({ children }) => {
  return (
    <AntLayout style={{ height: '100%' }}>
      <Sidebar />
      <AntLayout className="site-layout">
        <Header />
        <Content>{children}</Content>
      </AntLayout>
    </AntLayout>
  );
});
